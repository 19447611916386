import { useState, useEffect, MutableRefObject } from "react";

const usePosition = (
  anchoredElementRef: MutableRefObject<HTMLElement | null>,
  positionedElementRef: MutableRefObject<HTMLElement | null>,
): "above" | "below" => {
  const [position, setPosition] = useState<"above" | "below">("below");
  const TOP_NAV_HEIGHT = 76;

  useEffect(() => {
    const updatePosition = () => {
      if (anchoredElementRef.current && positionedElementRef.current) {
        const searchBoxRect =
          anchoredElementRef.current.getBoundingClientRect();
        const positionedDivRect =
          positionedElementRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        // Check if there's enough space below; otherwise, place above
        if (
          searchBoxRect.bottom + positionedDivRect.height > viewportHeight &&
          searchBoxRect.top - TOP_NAV_HEIGHT > positionedDivRect.height
        ) {
          setPosition("above");
        } else {
          setPosition("below");
        }
      }
    };

    updatePosition();
    window.addEventListener("resize", updatePosition);
    window.addEventListener("scroll", updatePosition);

    return () => {
      window.removeEventListener("resize", updatePosition);
      window.removeEventListener("scroll", updatePosition);
    };
  }, [anchoredElementRef, positionedElementRef]);

  return position;
};

export default usePosition;
