import type { IconProps } from "./types";

const defaultProps = {
  svgProps: {
    width: "33",
    height: "25",
    viewBox: "0 0 33 25",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const OpenQuote = ({
  svgProps = defaultProps.svgProps,
  pathProps = defaultProps.pathProps,
}: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  const mergedPathProps = { ...defaultProps.pathProps, ...pathProps };
  return (
    <svg {...mergedSvgProps}>
      <path
        d="M0 24.6973V15.4248C0 11.0303 1.04004 7.55859 3.12012 5.00977C5.22949 2.46094 8.29102 0.791016 12.3047 0V4.57031C9.31641 5.50781 7.30957 7.11914 6.28418 9.4043C5.75684 10.6055 5.53711 11.8506 5.625 13.1396H12.5244V24.6973H0ZM33.1348 4.57031C30.1758 5.47852 28.1836 7.11914 27.1582 9.49219C26.5723 10.752 26.3379 11.9678 26.4551 13.1396H33.3545V24.6973H20.8301V15.4248C20.8301 10.9717 21.8994 7.48535 24.0381 4.96582C26.2061 2.44629 29.2383 0.791016 33.1348 0V4.57031Z"
        {...mergedPathProps}
      ></path>
    </svg>
  );
};

export default OpenQuote;
