import React from "react";
import type { IconProps } from "./types";

const defaultProps = {
  svgProps: {
    width: "48",
    height: "48",
    viewBox: "0 0 48 48",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const X = ({ svgProps, pathProps }: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  const mergedPathProps = { ...defaultProps.pathProps, ...pathProps };

  return (
    <svg {...mergedSvgProps}>
      <path
        d="M21.2144 25.4036L12.2784 35.5671H14.3952L22.1576 26.7441L28.3528 35.5671H35.5L26.1296 22.2239L35.5 11.5671H33.3832L25.1912 20.8866L18.6472 11.5671H11.5M32.6192 34.0052H29.3672L14.3968 13.0523H17.6496"
        {...mergedPathProps}
      ></path>
    </svg>
  );
};

export default X;
