import Image from "next/image";

import { TrendingUp } from "../icons";
import { Program, Suggestions } from "./types";

import styles from "./index.module.css";
import React, { FC, ReactNode, useEffect, useMemo } from "react";

import useProductEvents from "@/hooks/eventing/useProductEvents";
import { ProductClicked } from "@/hooks/eventing/types/redventures.ecommerce.v1.ProductClicked";
import useElementEvents from "@/hooks/eventing/useElementEvents";
import { ElementClicked } from "@/hooks/eventing/types/redventures.usertracking.v3.ElementClicked";
import {
  DEFAULT_USE_IN_VIEW_OPTIONS,
  ElementType,
  FormatSubType,
  FormatType,
  NO_BRAND,
} from "@/constants/tagular/main";
import {
  EventCacheType,
  getBrandStringFromPartnerKeys,
  getTagularSubject,
  hyphenateForTagular,
  inEventCache,
  setCorrelationId,
} from "@/utils/tagular/helpers";
import { translateVariant } from "@/utils/tagular/dataTranslationMatrices";
import { autocompleteSuggestionClickEvent } from "@/lib/segment";
import { useInView } from "react-intersection-observer";

interface EmptyQueryHitsProps {
  suggestions: Suggestions;
  location?: string;
}

const ProgramLink: FC<{
  program: Program;
  index: number;
  children: ReactNode;
  location?: string;
}> = ({ program, index, children, location }) => {
  const position = index + 1;
  const url = program.href;

  const tagularProduct: ProductClicked["product"] = useMemo(
    () => ({
      brand: getBrandStringFromPartnerKeys([program.author || NO_BRAND]), // may not be complete, this really should represent the angolia db
      formatType: FormatType.Search,
      formatSubtype: FormatSubType.SearchResult,
      location: location,
      position: position,
      category: getTagularSubject(program.category),
      name: hyphenateForTagular(program.title),
      variant: translateVariant(program.courseType),
    }),
    [
      program.author,
      program.category,
      program.courseType,
      program.title,
      position,
      location,
    ],
  );

  const { productClicked, productViewed, correlationId } = useProductEvents({
    product: tagularProduct,
  });

  const handleClick = () => {
    setCorrelationId(correlationId);

    productClicked();

    autocompleteSuggestionClickEvent({
      indexName: "product",
      link: `${url}`,
      objectId: "",
      product: program.courseType,
      queryId: "",
      uuid: program.uuid,
    });
  };

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      if (!inEventCache(EventCacheType.ProductSearch, tagularProduct)) {
        productViewed();
      }
    }
  }, [inView, productViewed, tagularProduct]);

  return (
    <a
      ref={ref}
      href={`${url}`}
      className="no-underline flex items-center"
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

const TrendingNowLink: FC<{
  query: string;
  index: number;
  children: ReactNode;
  location?: string;
}> = ({ query, index, children, location }) => {
  const position = index + 1;

  const webElement: ElementClicked["webElement"] = useMemo(
    () => ({
      elementType: ElementType.Entry,
      position: `${position}`,
      location: location,
      text: query,
      name: hyphenateForTagular(query),
      htmlId: "trending-now",
    }),
    [position, query, location],
  );

  const outboundUrl = `/search?q=${query}`;

  const { elementClicked, elementViewed } = useElementEvents({
    webElement,
  });

  const { inView, ref } = useInView(DEFAULT_USE_IN_VIEW_OPTIONS);

  useEffect(() => {
    if (inView) {
      if (!inEventCache(EventCacheType.ProductSearch, webElement)) {
        elementViewed();
      }
    }
  }, [inView, elementViewed, webElement]);

  const handleClick = () => {
    elementClicked({ outboundUrl });
  };

  return (
    <a
      ref={ref}
      href={outboundUrl}
      className="no-underline flex items-center"
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

/**
 * Renders a component for displaying empty query hits.
 *
 * @returns JSX.Element
 */
export const EmptyQueryHits: FC<EmptyQueryHitsProps> = ({
  suggestions,
  location,
}) => {
  // TODO - Query these by id from algolia

  return (
    <div className="shadow-md rounded bg-white">
      <p className="text-sm font-bold ml-3 mb-0">{suggestions.popular.title}</p>
      <ul className="list-none !p-0 !m-0 text-xs">
        {suggestions.popular.programs.map((program: any, index: number) => (
          <li
            className={`m-0 px-3 py-2 ${styles.searchListItem}`}
            key={program.id}
          >
            <ProgramLink program={program} index={index} location={location}>
              <Image
                src={program.imageUrl}
                alt={program.title || ""}
                title={program.title}
                width={36}
                height={36}
                className="object-cover overflow-clip my-0 mr-2 w-9 h-9"
              />
              <div className="font-normal">
                <span className="text-sm lg:text-base block">
                  {program.title}
                </span>
                <span className="text-xs lg:text-sm block">
                  {program.author} | {program.courseType}
                </span>
              </div>
            </ProgramLink>
          </li>
        ))}
      </ul>
      <p className="text-sm font-bold ml-3 mb-0">
        {suggestions.trending.title}
      </p>
      <ul className="list-none p-0 m-0">
        {suggestions.trending.queries.map((query: string, index: number) => (
          <li className={`m-0 px-3 py-2 ${styles.searchListItem}`} key={query}>
            <TrendingNowLink query={query} index={index} location={location}>
              <TrendingUp />
              <span className="text-sm lg:text-base block ml-2">{query}</span>
            </TrendingNowLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

/**
 * Component that acts as a boundary for handling empty queries.
 * If there are no results or the query is empty, it renders the fallback content.
 * Otherwise, it renders the children.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content to render if there are results or a non-empty query.
 * @param {ReactNode} props.fallback - The fallback content to render if there are no results or an empty query.
 * @param {string} props.query - The search query.
 * @param {Array} props.results - The array of results.
 * @returns {ReactNode} The rendered component.
 */
export const EmptyQueryBoundary = ({
  children,
  fallback,
  query,
  results,
}: any) => {
  if (!query.trim() || results.length === 0) {
    return (
      <>
        {fallback}
        <div className="hidden">{children}</div>
      </>
    );
  }

  return children;
};
