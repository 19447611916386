import React from "react";
import type { IconProps } from "./types";

const defaultProps = {
  svgProps: {
    width: "100%",
    height: "100%",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const Compass = ({
  svgProps = defaultProps.svgProps,
  pathProps = defaultProps.pathProps,
}: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  const mergedPathProps = { ...defaultProps.pathProps, ...pathProps };

  return (
    <svg {...mergedSvgProps}>
      <path
        d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm-5.5-2.5 7.51-3.49L15.5 4.5 7.99 7.99 4.5 15.5ZM10 8.9c.61 0 1.1.49 1.1 1.1 0 .61-.49 1.1-1.1 1.1-.61 0-1.1-.49-1.1-1.1 0-.61.49-1.1 1.1-1.1Z"
        transform="scale(1.2)"
        vector-effect="non-scaling-stroke"
        {...mergedPathProps}
      ></path>
    </svg>
  );
};

export default Compass;
