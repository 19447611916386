import { BootcampPage, CatalogPageCustomization } from "@/types/Customizations";
import { PartialPartner, Partner } from "@/lib/partner/types";
import { CourseRun } from "@/lib/courseRun/types";
import { PriceRange } from "@/lib/program/types";
import { Instructor } from "@/lib/instructor/types";

export enum ProductType {
  EXECUTIVE_EDUCATION = "Executive Education",
  BOOTCAMP = "Boot Camp",
  COURSE = "Course",
}

export type PartialProgram = {
  subtitle: string;
  title: string;
  uuid: string;
  type?: string;
  marketingUrl?: string;
  priceRanges?: PriceRange[];
};

export type Video = {
  src: string | null;
  description: string | null;
  image: {
    description: string | null;
    src: string | null;
    width: number | null;
    height: number | null;
  };
};

export type Redirect = {
  created: Date;
  modified: Date;
  partnerId: number;
  value: string;
};

export type CourseSubject = {
  description?: string;
  languageCode: string;
  masterId: number;
  name: string;
  subtitle: string;
  uuid: string;
  slug: string;
  sortValue?: number | null;
};

export type ProcessedCourseSubject = CourseSubject & {
  inEdx?: boolean;
};

export type Collaborator = {
  created: string;
  image: string;
  modified: string;
  name: string;
  uuid: string;
};

export type Sponsor = {
  key: string;
  logoImageUrl: string;
  name: string;
  slug: string;
  uuid: string;
};

export type Course = {
  id: string;
  key: string;
  isDeleted: boolean;
  created: Date;
  externalId: string;
  canonicalCourseRunKey: string | null;
  productUuid: string;
  productName: string;
  productShortDescription: string;
  productType: ProductType;
  productFullDescription: string;
  productOverview: string;
  productUrl: string;
  productUrlSlug: string;
  productImageUrl: string;
  productVariantUuid?: string;
  objectID: string;
  // partnerKey: string;
  /** @deprecated Please use Course.Partners */
  partner?: Partner | null;
  partners: PartialPartner[];
  aboutThisCourse?: string;
  subjectEs: string;
  subjectEn: string;
  subjects: CourseSubject[] | null;
  level?: string;
  prerequisites: string[];
  productVariantLanguageId?: string;
  partnerNameOverride: string | null;
  partnerLogoOverride: string | null;
  associatedSkills: string[];
  /** @deprecated Please use Course.productOverview */
  whatYoullLearn?: string[];
  frequentlyAskedQuestions: string | null;
  /** @deprecated Nothing ever sets this value. Please use subjects */
  attributes?: string[];
  courseRunStatuses: { key: string; status: string; uuid: string }[];
  courseRunKeys: string[];
  courseRuns?: CourseRun[];
  hash?: string;
  skills: {
    description: string;
    infoUrl: string;
    typeId: string;
    typeName: string;
    skill: string;
    category: string;
    subcategory: string;
  }[];
  redirects: Redirect[];
  urlSlugHistory: {
    slug: string;
    isActive: boolean;
  }[];
  programs: PartialProgram[] | null;
  customizations: {
    catalogPage: CatalogPageCustomization | null;
    bootcampPage: BootcampPage | null;
  };
  prerequisitesHtml: string;
  ratings: {
    average: number;
    count: number;
  } | null;
  syllabusHtml: string;
  modified: Date;
  advertisedCourseRunUuid: string;
  hasOfacRestrictions: boolean | null;
  enrollmentCount: number;
  learnerTestimonials: string | null;
  video: Video;
  levelType: string;
  sponsors: Sponsor[];
  collaborators: Collaborator[];
  additionalInformation: string;
  additionalMetadata: AdditionalMetadata[];
  sortValue?: number | null;
};

export type CourseEntitlement = {
  mode: string | null;
  price: number | null;
  currency: string | null;
  sku: string | null;
  expires: string | null;
};

export type ProcessedCourse = Course & {
  cardType?: string;
  courseCount?: number;
  activeCourseRun: CourseRun | null;
  activeCourseRuns?: CourseRun[];
  isActive: boolean;
  inEdx: boolean;
  isPreview: boolean;
  hasOfacRestrictions: boolean;
  edxPath: string;
  subjects: ProcessedCourseSubject[] | null;
  subjectEn: string;
  entitlements?: CourseEntitlement[];
  additionalMetadata: AdditionalMetadata[] | null;
  organizationShortCodeOverride?: string;
  organizationLogoOverrideUrl?: string;
};

export type CourseDetails = {
  productUuid: string;
  advertisedCourseRunUuid: string;
  productName: string;
  productShortDescription: string;
  productUrlSlug: string;
  instructors: Omit<Instructor, "aboutMe">[];
};

export type AdditionalMetadata = {
  startDate?: Date | null;
  created?: Date | null;
  endDate?: Date | null;
  externalIdentifier: string | null;
  externalUrl: string | null;
  leadCaptureFormUrl: string | null;
  modified?: Date | null;
  organicUrl: string | null;
  productStatus: string | null;
  courseTermOverride: string | null;
  externalCourseMarketingType: string | null;
  registrationDeadline: string | null;
  displayOnOrgPage: boolean | null;
  certificateInfo: {
    heading?: string | null;
    blurb?: string | null;
  }[];
  facts: {
    heading?: string | null;
    blurb?: string | null;
  }[];
  productMeta: {
    title?: string | null;
    description?: string | null;
    keywords?:
      | {
          name?: string | null;
          slug?: string | null;
        }[]
      | null;
  }[];
  taxiForm: {
    formId?: string | null;
    grouping?: string | null;
    title?: string | null;
    subtitle?: string | null;
    postSubmitUrl?: string | null;
  }[];
};
