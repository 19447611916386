import type { IconProps } from "./types";
const defaultProps = {
  svgProps: {
    width: "28",
    height: "28",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const Facebook = ({
  svgProps = defaultProps.svgProps,
  pathProps = defaultProps.pathProps,
}: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  const mergedPathProps = { ...defaultProps.pathProps, ...pathProps };
  return (
    <svg {...mergedSvgProps}>
      <path
        d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95z"
        {...mergedPathProps}
      ></path>
    </svg>
  );
};

export default Facebook;
