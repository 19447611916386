"use client";
import { useEffect, useMemo, useState } from "react";

import type { Hit } from "@/components/ProductSearch/types";
import { PRODUCT_INDEX_EN } from "@/components/Search/constants";
import getPublicClient from "@/lib/algolia/getPublicClient";
import { ProductType as CourseType } from "@/lib/course/types";
import logger from "@/lib/logger";

const getHits = async (client: any, searchParams: { [key: string]: any }) => {
  const params = { ...searchParams };
  const { hits, queryID } = await client.searchSingleIndex({
    indexName: PRODUCT_INDEX_EN,
    searchParams: params,
  });
  return hits.map((hit: Hit) => ({ ...hit, queryID }));
};

const processAutocompleteHits = (productHits: Hit[]) => {
  // we need to filter out potential duplicates if some EE or Boot Camps products were returned in both queries
  const executiveEducation = productHits
    .filter((hit) => hit.product == CourseType.EXECUTIVE_EDUCATION)
    .slice(0, 2);

  const generalHits = productHits.filter(
    (hit) => hit.product != CourseType.EXECUTIVE_EDUCATION,
  );
  const processedHits = [...executiveEducation, ...generalHits];
  return processedHits.slice(0, 10);
};

const handleError = (error: Error) => {
  const message = `Failed to fetch products for autocomplete`;
  logger.error(message, error);
};

const useAutocomplete = (query: string) => {
  const client = getPublicClient();
  const [productHits, setProductHits] = useState<Hit[]>([]);

  useEffect(() => {
    if (query) {
      const searchParams = {
        query,
        clickAnalytics: true,
        restrictSearchableAttributes: ["title"],
        attributesToRetrieve: [
          "title",
          "marketing_url",
          "subject",
          "partner_keys",
          "uuid",
          "product",
          "program_type",
        ],
        highlightPreTag: `<em class="bg-transparent font-bold not-italic">`,
        highlightPostTag: "</em>",
        hitsPerPage: 250,
      };
      const getProductHits = async () => {
        const hits = await getHits(client, searchParams);
        setProductHits(hits);
      };

      getProductHits().catch((e) => handleError(e));
    }
  }, [query, client]);

  const processedHits = useMemo(
    () => processAutocompleteHits(productHits),
    [productHits],
  );

  return processedHits;
};

export default useAutocomplete;
