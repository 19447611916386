import { EVENTS } from "./types/events";

import makeEvent from "./makeEvent";
import { ElementClicked } from "./types/redventures.usertracking.v3.ElementClicked";
import { ElementViewed } from "./types/redventures.usertracking.v3.ElementViewed";

type ElementEvents = ElementClicked & ElementViewed;

type UseElementEvents = (data: ElementEvents) => {
  elementClicked: (overrideProps?: Partial<ElementClicked>) => void;
  elementViewed: (overrideProps?: Partial<ElementViewed>) => void;
};

const useElementEvents: UseElementEvents = (data) => {
  const elementEvent = makeEvent<ElementEvents, EVENTS>(data);
  return {
    elementClicked: elementEvent<ElementClicked>(EVENTS.ElementClicked),
    elementViewed: elementEvent<ElementViewed>(EVENTS.ElementViewed),
  };
};

export default useElementEvents;
