import type { IconProps } from "./types";

const defaultProps = {
  svgProps: {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const ArrowRightAlt = ({
  svgProps = defaultProps.svgProps,
  pathProps = defaultProps.pathProps,
}: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  const mergedPathProps = { ...defaultProps.pathProps, ...pathProps };
  return (
    <svg {...mergedSvgProps}>
      <path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4v3z" {...mergedPathProps} />
    </svg>
  );
};

export default ArrowRightAlt;
