import { ProductClicked } from "./types/redventures.ecommerce.v1.ProductClicked";
import { ProductLoaded } from "./types/redventures.ecommerce.v1.ProductLoaded";
import { ProductViewed } from "./types/redventures.ecommerce.v1.ProductViewed";
import { EVENTS } from "./types/events";
import { useRef, useState } from "react";
import { v4 as uuid } from "uuid";

import makeEvent from "./makeEvent";

type ProductEvents = ProductLoaded & ProductClicked & ProductViewed;

type UseProductEvents = (data: ProductEvents) => {
  productClicked: (overrideProps?: Partial<ProductClicked>) => void;
  productLoaded: (overrideProps?: Partial<ProductLoaded>) => void;
  productViewed: (overrideProps?: Partial<ProductViewed>) => void;
  correlationId: string;
};

const useProductEvents: UseProductEvents = (data) => {
  const viewCorrelationId = useRef<string>(data.viewCorrelationId ?? uuid());
  const [correlationId, setCorrelationId] = useState(
    () => data.correlationId ?? uuid(),
  );

  const productEvent = makeEvent<ProductEvents, EVENTS>({
    ...data,
    viewCorrelationId: viewCorrelationId.current,
  });

  const deferCorrelationIdUpdate = async () => {
    setTimeout(() => setCorrelationId(uuid()), 0);
  };

  return {
    productClicked: (override) => {
      productEvent<ProductClicked>(EVENTS.ProductClicked)({
        actionOutcome: "Click",
        correlationId,
        ...override,
      });

      deferCorrelationIdUpdate();
    },
    productLoaded: productEvent<ProductLoaded>(EVENTS.ProductLoaded),
    productViewed: productEvent<ProductViewed>(EVENTS.ProductViewed),
    correlationId,
  };
};

export default useProductEvents;
