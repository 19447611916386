import { shouldDebugWithKey } from "@/lib/utils";
import { ProcessedCourse } from "@/lib/course/types";

const SEGMENT_DEBUG_EVENT_KEY = "segmentDebug";

type ButtonId = string;

type Events =
  | "edx.bi.user.search.header-search-bar.submitted"
  | "edx.bi.user.search-query.submitted"
  | "edx.bi.user.search.homepage.submitted"
  | "edx.bi.2u-product-card.clicked"
  | "edx.bi.user.discovery.card.click"
  | "edx.bi.user.autoComplete.suggestion.click"
  | "edx.bi.user.course-details.toggle-email-opt-in"
  | "edx.bi.exec-ed-form-submit.clicked";

enum PageName {
  HOME = "home",
  PRODUCT = "product landing page",
  SHOP = "shop-page",
  SEARCH = "search",
  COURSE = "course",
  LEARN = "learn page",
  PROGRAM = "program",
  BOOTCAMP = "bootcamp page",
}

type SegmentEventData = Record<
  string,
  string | number | boolean | undefined | unknown
>;

export type TaxiFormSegmentEventData = {
  eventName: "execEd";
  data: { course: ProcessedCourse } & SegmentEventData;
};

const sendEvent = (event: Events, data: SegmentEventData) => {
  if (shouldDebugWithKey(SEGMENT_DEBUG_EVENT_KEY)) {
    console.log("[Segment] Sending Event", {
      event,
      data,
    });
  }

  window.analytics?.track?.(event, data);
};

const is2uCourse = (value: string) =>
  [
    "bachelors",
    "bootcamp",
    "boot-camp",
    "executive-education",
    "masters",
    "doctorate",
    "license",
    "certificate",
  ].includes(value);

export const createCodeFriendlyProduct = (type?: string) =>
  type?.replace(/\s+/g, "-").replace(/'/g, "").toLowerCase();

const is2uProduct = (data: ProductCardClickedData) => {
  if (
    data.productSource?.toLowerCase() === "2u" ||
    data.product?.toLowerCase() === "2u degree"
  ) {
    return true;
  }

  if (
    data.product === undefined &&
    data.productSource === undefined &&
    data?.productCategory &&
    is2uCourse(createCodeFriendlyProduct(data.productCategory) as string)
  ) {
    return true;
  }

  return false;
};

export const getPageNameFromUrl = (): PageName | undefined => {
  const { pathname } = new URL(window.location.href);
  const courseRegex = /^\/learn\/[^\/]+\/[^\/]+$/;
  const execEdRegex = /^\/executive-education(\/[^\/]+)?$/;
  const learnPageRegex = /^\/learn(\/[^\/]+)?$/;
  const programRegex = /^\/certificates\/[^\/]+\/[^\/]+$/;
  const bootcampPageRegex = /^\/boot-camps\/[^\/]+\/[^\/]+$/;
  const productPageRegex = /^(\/(certificates|boot-camps))(\/[^\/]+)?$/;

  if (pathname === "/") {
    return PageName.HOME;
  }

  if (productPageRegex.test(pathname)) {
    return PageName.PRODUCT;
  }

  if (pathname === "/courses") {
    return PageName.SHOP;
  }

  if (pathname === "/search") {
    return PageName.SEARCH;
  }

  if (courseRegex.test(pathname) || execEdRegex.test(pathname)) {
    return PageName.COURSE;
  }

  if (learnPageRegex.test(pathname)) {
    return PageName.LEARN;
  }

  if (programRegex.test(pathname)) {
    return PageName.PROGRAM;
  }

  if (bootcampPageRegex.test(pathname)) {
    return PageName.BOOTCAMP;
  }

  return undefined;
};

export type HeaderSearchBarSubmittedData = {
  page?: string;
  mobile?: boolean;
  url?: string;
  label?: string;
  courseUuid?: string;
  activeCourseRunKey?: string;
  isSelfPaced?: boolean;
  subject?: string;
  programUuid?: string;
  path?: string;
};

export const headerSearchBarSubmittedEvent = (
  data?: HeaderSearchBarSubmittedData,
) =>
  sendEvent("edx.bi.user.search.header-search-bar.submitted", {
    page: data?.page ?? getPageNameFromUrl(),
    mobile: Boolean(data?.mobile),
    url: data?.url ?? window.location.href,
    component: "search-bar",
    components: "navbar-search-box",
    category: "search",
    label: data?.label ?? "",
    course_uuid: data?.courseUuid,
    active_course_run_key: data?.activeCourseRunKey,
    is_self_paced: data?.isSelfPaced,
    subject: data?.subject,
    program_uuid: data?.programUuid,
    path: data?.path,
  });

export type SearchQuerySubmittedData = {
  page?: string;
  mobile?: boolean;
  url?: string;
  label?: string;
  fromViewAllButton?: boolean;
  subject?: string;
  programUuid?: string;
  path?: string;
};

export const embeddedSearchQuerySubmittedEvent = (
  data?: SearchQuerySubmittedData,
) => {
  const page = data?.page ?? getPageNameFromUrl();
  const isHomePage = page === PageName.HOME;
  const event = isHomePage
    ? "edx.bi.user.search.homepage.submitted"
    : "edx.bi.user.search-query.submitted";

  sendEvent(event, {
    page,
    label: data?.label ?? "",
    mobile: Boolean(data?.mobile),
    url: data?.url ?? window.location.href,
    component: isHomePage ? "home-search-box" : "inner-autocomplete",
    category: "search",
    fromViewAllButton: Boolean(data?.fromViewAllButton),
    subject: data?.subject,
    program_uuid: data?.programUuid,
    path: data?.path,
  });
};

type AutoCompleteSuggestionClickData = {
  category?: string;
  link?: string;
  indexName?: string;
  objectId?: string;
  product?: string;
  position?: string;
  queryId?: string;
  uuid?: string;
  page?: string;
};
export const autocompleteSuggestionClickEvent = ({
  queryId,
  objectId,
  ...data
}: AutoCompleteSuggestionClickData) => {
  const page = data?.page ?? getPageNameFromUrl();

  sendEvent("edx.bi.user.autoComplete.suggestion.click", {
    category: "navigation",
    queryID: queryId,
    objectID: objectId,
    page,
    ...data,
  });
};

type ProductCardClickedData = {
  cardVisibility?: string;
  category?: string;
  isProductCardVariation?: boolean;
  label?: string;
  link?: string;
  productCategory?: string;
  productLine?: string;
  product?: string;
  productSource?: string;
  objectId?: string;
  queryId?: string;
  title?: string;
  resultLevel?: string;
  uuid?: string;
  index?: string;
  activeRunKey?: string;
  position?: number;
  domElement: Element;
};

export const bindProductCardClickedEvent = (data: ProductCardClickedData) => {
  const eventParams = {
    category: "navigation",
    link: data.link,
    index: "product",
    hasDeviceParam: true,
    results_level: data.resultLevel
      ? `${data.resultLevel}-level-results`
      : undefined,
    objectID: data.objectId,
    position: data.position,
    queryID: data.queryId,
    card_visibility: "visible",
    is_product_card_variation: false,
  };

  const is2u = is2uProduct(data);
  const isSearchPage = getPageNameFromUrl() === PageName.SEARCH;

  if (is2u) {
    window.analytics?.trackLink?.(
      data.domElement,
      "edx.bi.2u-product-card.clicked",
      {
        ...eventParams,
        label: data.title,
        product_category:
          isSearchPage && data.resultLevel === "second"
            ? `2U Search: ${data.productCategory}`
            : "2u",
        product_line: createCodeFriendlyProduct(data.productCategory),
        source: "2u",
      },
    );

    return;
  }

  const isProgram = data.product?.toLowerCase() === "program";

  window.analytics?.trackLink?.(
    data.domElement,
    "edx.bi.user.discovery.card.click",
    {
      ...eventParams,
      product_category: isProgram ? "program" : "course",
      label: isProgram ? `${data.title} [${data.uuid}]` : data.activeRunKey,
      uuid: isProgram ? undefined : data?.uuid,
      source: "edX",
    },
  );
};

export type EnrollmentButtonClickEventData = {
  domElement?: Element | null;
  page?: string;
  subject?: string;
  url?: string;
  mobile?: string;
  activeCourseRunUuid?: string;
  activeCourseRunKey?: string;
  courseUuid?: string;
  isSelfPaced?: boolean;
  category?: "navigation";
  link?: string | {};
  label?: string;
  userEnrolled?: boolean;
  userEntitled?: boolean;
  userLoggedIn?: boolean;
  emailOptIn?: boolean;
  objectId?: `course-${string}`;
  index?: string | undefined;
  queryId?: string | undefined;
  position?: number;
  buttonId?: ButtonId;
  component?: string;
  reAttachSegmentEvent?: boolean;
};

export const numberOr = (x: string | null | undefined, or: number): number => {
  if (!x) return or;

  const xNum = Number(x);

  if (isNaN(xNum)) return or;

  return xNum;
};

export const enrollButtonClickEvent = (
  data: EnrollmentButtonClickEventData,
  updateLoggedOutLinkRef?: Function,
) => {
  const {
    category,
    component,
    activeCourseRunUuid,
    activeCourseRunKey,
    courseUuid,
    isSelfPaced,
    userEnrolled,
    userEntitled,
    userLoggedIn,
    emailOptIn,
    objectId,
    label,
    link,
    position,
    queryId,
    index,
    domElement,
    ...rest
  } = data;

  // Base payload for all scenarios
  let payload: Record<string, unknown> = {
    category: category,
    user_enrolled: userEnrolled,
    user_entitled: userEntitled ?? false,
    user_logged_in: userLoggedIn,
    email_opt_in: emailOptIn,
    label: label ?? activeCourseRunKey,
    link: link,
    objectID: objectId ?? `course-${courseUuid}`,
    page: getPageNameFromUrl(),
    subject: data.subject,
    url: data.url,
    mobile: data.mobile,
    ...(activeCourseRunUuid && {
      active_course_run_uuid: activeCourseRunUuid,
    }),
    active_course_run_key: activeCourseRunKey,
    course_uuid: courseUuid,
    is_self_paced: isSelfPaced,
    component: component,
  };

  if (index) {
    payload = {
      ...payload,
      index: index,
      position: position,
      queryId: queryId,
      products: [{ objectID: objectId ?? `course-${courseUuid}` }],
    };
  }

  const eventName =
    `edx.bi.link.enrollment.${data.buttonId ?? ""}clicked` as Events;

  if (domElement) {
    if (data.reAttachSegmentEvent) {
      // emailOptIn has been toggled, remove existing event listener by clone and replace previous element.
      const clonedElement = domElement.cloneNode(true) as HTMLAnchorElement;
      if (clonedElement) {
        domElement.replaceWith(clonedElement);

        // Need to update the link reference to the new element for multiple emailOptIn toggles scenario
        if (updateLoggedOutLinkRef) {
          updateLoggedOutLinkRef(clonedElement);
        }
        window.analytics?.trackLink(clonedElement, eventName, payload);
      }
    } else {
      window.analytics?.trackLink(domElement, eventName, payload);
    }
  } else {
    sendEvent(eventName, payload);
  }
};

export type ToggleEmailOptInData = {
  courseRunKey: string;
  optIn?: boolean;
};

export const toggleEmailOptIn = ({
  courseRunKey,
  optIn = false,
}: ToggleEmailOptInData) => {
  sendEvent("edx.bi.user.course-details.toggle-email-opt-in", {
    category: "course-details",
    label: courseRunKey,
    email_opt_in: optIn,
  });
};

export type SendExecEdCourseTaxiFormEventData = {
  course: ProcessedCourse;
};

const sendExecEdCourseTaxiFormEvent = ({
  course,
}: SendExecEdCourseTaxiFormEventData) => {
  // Stolen from:
  //  https://github.com/edx/prospectus/blob/1e537663511d3610f37d2972a74685914d76a888/src/packages/exec-ed-page/template/ExecEdDetailPage/index.jsx#L625

  const eventData = {
    category: "exec-ed-form",
    page: "course",
    subject: course.subjects?.[0].slug,
    url: location.href,
    mobile: false,
    active_course_run_uuid: course.activeCourseRun
      ? course.activeCourseRun.uuid
      : "",
    active_course_run_key: course.activeCourseRun
      ? course.activeCourseRun.key
      : "",
    course_uuid: course.productUuid,
    is_self_paced: course.activeCourseRun
      ? course.activeCourseRun.pacingType === "self_paced"
      : true,
  };

  sendEvent("edx.bi.exec-ed-form-submit.clicked", eventData);
};

/**
 * Handle the Serialized TaxiForm Events for Segment
 *
 * > NOTE: Taxi Forms might need different Segment Events, and some don't have them, however you can't pass a callback
 * > in to the component due to its mixed SSR/CSR makeup. So we have to handle it specially.
 * @param data
 */
export const sendTaxiFormEvent = (data: TaxiFormSegmentEventData) => {
  const unknownData = data.data as unknown;
  switch (data.eventName) {
    case "execEd":
      sendExecEdCourseTaxiFormEvent(
        unknownData as SendExecEdCourseTaxiFormEventData,
      );
  }
};
