import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
} from "react";

type State = {
  query: string;
};

type Action = {
  type: "SET_QUERY";
  payload: string;
};

const ProductSearchContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: { query: "" },
  dispatch: () => null,
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_QUERY":
      return { ...state, query: action.payload };
    default:
      return state;
  }
};

type ProductSearchContextProviderProps = {
  children: ReactNode;
  query: string;
};

export const ProductSearchContextProvider = ({
  children,
  query,
}: ProductSearchContextProviderProps) => {
  const initialState: State = { query };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProductSearchContext.Provider value={{ state, dispatch }}>
      {children}
    </ProductSearchContext.Provider>
  );
};

export const useProductSearchContext = () => useContext(ProductSearchContext);

export const useProductSearchQuery = () => {
  const { dispatch, state } = useProductSearchContext();

  const setQuery = (query: string) => {
    dispatch({ type: "SET_QUERY", payload: query });
  };

  return {
    setQuery,
    query: state.query,
  };
};
