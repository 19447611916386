import { tagular, cohesion } from "@highereducation/cohesion-utils-react";
import { isTagularEnabledForPage } from "@/utils/tagular/helpers";
import { EventName } from "@/constants/tagular/events";

const isTagularAvailable = (): boolean =>
  typeof window !== "undefined" && typeof window.tagular === "function";

let eventsQueue: Record<EventName, unknown>[] = [];

const beam = () => {
  if (typeof window !== "undefined") {
    cohesion("ready", () => {
      eventsQueue.forEach(beam);
      eventsQueue = [];
    });
  }

  return (event: Record<EventName, unknown>): void => {
    if (
      !isTagularEnabledForPage() ||
      (typeof window !== "undefined" && window.STORYBOOK_FRAMEWORK)
    ) {
      return;
    }

    if (!isTagularAvailable()) {
      eventsQueue.push(event);
      return;
    }

    tagular("beam", event);
  };
};

export default beam();
