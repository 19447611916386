import { version } from "os";
import type { IconProps } from "./types";
const defaultProps = {
  svgProps: {
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    x: "0px",
    y: "0px",
    viewBox: "0 0 160 83.6",
    "xml:space": "preserve",
  },
};

const EdxBy2U = ({ svgProps = defaultProps.svgProps }: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  return (
    <svg {...mergedSvgProps}>
      <g id="Layer_1-2">
        <g>
          <path
            d="M158,16.5c0-0.8-0.6-1.2-1.4-1.2h-1.7v3.7h0.7v-1.3h0.8l0.9,1.3h0.9l-1-1.4C157.7,17.5,158,17.1,158,16.5
			L158,16.5z M156.5,17.2h-0.9V16h0.9c0.5,0,0.8,0.2,0.8,0.6C157.3,16.9,157,17.2,156.5,17.2L156.5,17.2z M156.3,13.6
			c-2.1,0-3.7,1.7-3.7,3.7v0c0,2,1.6,3.7,3.7,3.7s3.7-1.7,3.7-3.7v0C160,15.3,158.4,13.6,156.3,13.6z M159.6,17.3
			c0,1.8-1.4,3.3-3.3,3.3s-3.3-1.5-3.3-3.3v0c0-1.8,1.4-3.3,3.3-3.3S159.6,15.5,159.6,17.3L159.6,17.3z M95.8,13.7h54l-15,69.9H67
			l3.9-17.7H0L13.9,0h84.8L95.8,13.7L95.8,13.7z"
            fill="#FFFFFF"
          />
          <path
            d="M42.1,25.6c-1.2-1.2-2.6-2.1-4.2-2.7c-1.6-0.6-3.3-0.9-5-0.9c-2.6,0-5,0.5-7.3,1.5c-2.2,1-4.1,2.5-5.7,4.3
			c-1.6,1.9-2.8,4.1-3.7,6.7c-0.9,2.6-1.3,5.5-1.3,8.6c0,2.3,0.4,4.4,1.1,6.1c0.7,1.8,1.7,3.3,3,4.5c1.3,1.2,2.8,2.1,4.5,2.8
			c1.7,0.6,3.5,0.9,5.4,0.9s3.9-0.2,5.5-0.7c1.7-0.5,3.2-1.1,4.4-2s2.3-1.8,3.2-3c0.8-1.1,1.4-2.4,1.8-3.7h-5.9
			c-0.7,1.3-1.8,2.4-3.2,3.2c-1.4,0.8-3.2,1.2-5.5,1.2c-1,0-2.1-0.2-3.1-0.5c-1-0.4-1.9-0.9-2.8-1.7s-1.5-1.8-2-3
			c-0.5-1.2-0.8-2.7-0.8-4.5c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0.1-0.9h24.9c0.1-0.3,0.1-0.7,0.2-1.2c0-0.5,0.1-0.9,0.1-1.4
			c0-0.5,0.1-0.9,0.1-1.4c0-0.5,0-0.9,0-1.2c0-2.2-0.4-4.2-1.1-5.9C44.2,28.2,43.2,26.8,42.1,25.6L42.1,25.6L42.1,25.6z M40.2,36
			c0,0.3,0,0.5-0.1,0.6H21.4c0.4-1.5,1-2.8,1.6-4c0.7-1.2,1.5-2.2,2.4-3.1c0.9-0.9,2-1.5,3.1-2c1.2-0.5,2.4-0.7,3.7-0.7
			s2.4,0.2,3.4,0.7c1,0.4,1.8,1,2.5,1.8c0.7,0.8,1.2,1.6,1.5,2.7c0.3,1,0.5,2.1,0.5,3.2C40.2,35.4,40.2,35.7,40.2,36L40.2,36z
			 M115.6,48.3l12,26.3h-12.4l-8.1-17.7h-1L91,74.6H78.7L102.1,47L91.3,22.7h12.6l7.2,16.8h0.7l13.6-16.8h12.5L115.6,48.3
			L115.6,48.3L115.6,48.3z M82.1,9l-4.3,19.9h-0.5c-0.3-1-0.8-2-1.4-2.8c-0.6-0.9-1.4-1.6-2.3-2.2c-0.9-0.6-1.9-1.1-3-1.4
			c-1.1-0.3-2.3-0.5-3.5-0.5c-1.7,0-3.3,0.2-4.8,0.7c-1.5,0.5-2.9,1.2-4.3,2.1c-1.3,0.9-2.5,2-3.6,3.3c-1.1,1.3-2,2.7-2.8,4.3
			c-0.8,1.6-1.4,3.3-1.8,5.2c-0.4,1.9-0.6,3.8-0.6,5.9c0,2.1,0.3,4,1,5.8c0.7,1.7,1.6,3.2,2.7,4.4c1.1,1.2,2.5,2.1,4,2.8
			c1.5,0.7,3.1,1,4.9,1c2.2,0,4.2-0.5,6.2-1.4c1.9-0.9,3.5-2.2,4.7-3.9h0.5l-1,4.7h5.3L87.7,9L82.1,9L82.1,9z M75.1,42.8
			c-0.6,2-1.5,3.7-2.7,5.1s-2.5,2.6-4,3.4c-1.5,0.8-3.2,1.3-5,1.3c-1.3,0-2.4-0.2-3.5-0.7c-1-0.5-1.9-1.1-2.7-2s-1.3-1.9-1.7-3.1
			c-0.4-1.2-0.6-2.5-0.6-4c0-2.3,0.3-4.4,1-6.4s1.5-3.6,2.6-5c1.1-1.4,2.4-2.5,3.9-3.3c1.5-0.8,3.2-1.2,4.9-1.2
			c1.3,0,2.6,0.2,3.6,0.7c1.1,0.4,2,1.1,2.7,1.9c0.7,0.8,1.3,1.8,1.7,3c0.4,1.2,0.6,2.5,0.6,3.9C76,38.7,75.7,40.8,75.1,42.8
			L75.1,42.8z"
            fill="#002022"
          />
          <path
            d="M25.4,81.1v-8.4h1.9v3c0.5-0.6,1.1-0.9,1.8-0.9c1.5,0,2.7,1.2,2.7,3.2c0,2-1.2,3.2-2.7,3.2
			c-0.7,0-1.3-0.3-1.8-0.9v0.7H25.4z M27.3,79c0.2,0.3,0.7,0.5,1.2,0.5c0.8,0,1.3-0.6,1.3-1.5c0-0.9-0.6-1.5-1.3-1.5
			c-0.4,0-0.9,0.2-1.2,0.6V79L27.3,79z"
            fill="#FFFFFF"
          />
          <path
            d="M32.6,81.8c0.1,0.1,0.3,0.1,0.5,0.1c0.4,0,0.7-0.1,0.8-0.3l0.1-0.3l-2.4-6.1h2l1.4,3.8l1.4-3.8h2.1L35.7,82
			c-0.5,1.3-1.4,1.6-2.6,1.6c-0.2,0-0.6,0-0.8-0.1L32.6,81.8L32.6,81.8L32.6,81.8z"
            fill="#FFFFFF"
          />
          <path
            d="M60.3,79.1c0,2.4-2,4.4-4.4,4.4s-4.4-2-4.4-4.4V70h3.2v9.2c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V70 h3.2V79.1L60.3,79.1z M51.4,81.1c-0.1-0.2-0.2-0.4-0.2-0.6h-4.6c0,0,1.4-1.2,3.1-2.9c0.9-0.9,1.3-2,1.3-3.1l0,0 c0-1.1-0.4-2.3-1.3-3.1c-1.7-1.7-4.5-1.7-6.2,0c-0.9,0.9-1.3,2-1.3,3.1h3.2c0-0.3,0.1-0.7,0.4-0.9c0.5-0.5,1.3-0.5,1.8,0 s0.5,1.3,0,1.8c0,0-2.5,2.4-4.7,4.5c-0.4,0.4-0.6,0.9-0.6,1.5c0,1.2,1,2.2,2.2,2.2h9.4c-0.5-0.3-0.9-0.6-1.3-1 C52,82.2,51.6,81.7,51.4,81.1C51.4,81.1,51.4,81.1,51.4,81.1z"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </svg>
  );
};

export default EdxBy2U;
