import deepmerge from "deepmerge";
import beam from "./beam";

const makeEvent =
  <Data, Events>(data: Data) =>
  <T extends unknown>(eventType: Events) =>
  (overrideProps: Partial<T> = {}) => {
    const merged = deepmerge(data as Record<string, unknown>, overrideProps);

    const event = {
      ...merged,
      "@type": eventType,
    };

    beam(event);
  };

export default makeEvent;
