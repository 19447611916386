import type { IconProps } from "./types";
const defaultProps = {
  svgProps: {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const CreditCard = ({
  svgProps = defaultProps.svgProps,
  pathProps = defaultProps.pathProps,
}: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  const mergedPathProps = { ...defaultProps.pathProps, ...pathProps };
  return (
    <svg {...mergedSvgProps}>
      <path
        d="M22 4H2.01L2 20h20V4zm-2 14H4v-6h16v6zm0-10H4V6h16v2z"
        {...mergedPathProps}
      ></path>
    </svg>
  );
};

export default CreditCard;
