/**
 * Converts the given url into a relative URL by removing the origin.
 *
 * https://edx.org/some-path/here -> /some-path/here
 */
export default function convertUrlToRelative(urlString: string): string {
  try {
    const url = new URL(urlString);
    return url.toString().replace(`${url.origin}/`, "");
  } catch (error) {
    return urlString;
  }
}
