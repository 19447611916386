import React from "react";
import type { IconProps } from "./types";

const defaultProps = {
  svgProps: {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const Lightbulb = ({
  svgProps = defaultProps.svgProps,
  pathProps = defaultProps.pathProps,
}: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  const mergedPathProps = { ...defaultProps.pathProps, ...pathProps };

  return (
    <svg {...mergedSvgProps}>
      <path
        d="M8 4.22074C5.59364 4.22074 3.63636 6.12408 3.63636 8.46413C3.63636 8.85488 3.96182 9.17137 4.36364 9.17137C4.76545 9.17137 5.09091 8.85488 5.09091 8.46413C5.09091 6.9038 6.39636 5.6352 8 5.6352C8.40182 5.6352 8.72727 5.31871 8.72727 4.92797C8.72727 4.53722 8.40182 4.22074 8 4.22074ZM4.36636 20.9808C4.36636 21.1201 4.40864 21.2558 4.48818 21.3716L5.60227 23C5.73727 23.1971 5.96455 23.3156 6.20773 23.3156H9.79182C10.0355 23.3156 10.2627 23.1971 10.3973 23L11.5114 21.3716C11.5905 21.2558 11.6327 21.1196 11.6332 20.9808L11.6355 19.0722H4.36455L4.36636 20.9808ZM8 0.68457C3.35091 0.68457 0 4.35202 0 8.46413C0 10.4254 0.747727 12.2147 1.98 13.5818C2.73636 14.4212 3.92273 16.1809 4.36273 17.6555V17.6582H6.54455V17.6529C6.54409 17.442 6.51182 17.2325 6.44682 17.0309C6.19273 16.2437 5.40955 14.168 3.62091 12.1833C2.68727 11.1476 2.18818 9.83395 2.18409 8.46413C2.175 5.20909 4.89636 2.80627 8 2.80627C11.2082 2.80627 13.8182 5.34435 13.8182 8.46413C13.8182 9.83307 13.3073 11.1538 12.3795 12.1833C10.6018 14.1551 9.815 16.2265 9.55682 17.0217C9.49019 17.2261 9.45615 17.4393 9.45591 17.6537V17.6582H11.6377V17.656C12.0777 16.1809 13.2641 14.4212 14.0205 13.5823C15.2523 12.2147 16 10.4254 16 8.46413C16 4.16769 12.4182 0.68457 8 0.68457Z"
        {...mergedPathProps}
      ></path>
    </svg>
  );
};

export default Lightbulb;
