import type { IconProps } from "./types";

const defaultProps = {
  svgProps: {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    role: "img",
    focusable: false,
    "aria-hidden": true,
  },
  pathProps: {
    fill: "currentColor",
  },
};

const Program = ({
  svgProps = defaultProps.svgProps,
  pathProps = defaultProps.pathProps,
}: IconProps) => {
  const mergedSvgProps = { ...defaultProps.svgProps, ...svgProps };
  const mergedPathProps = { ...defaultProps.pathProps, ...pathProps };
  return (
    <svg {...mergedSvgProps}>
      <path
        d="M14.2632 3H4V14.8421H5.46617V4.48026H14.2632V3Z"
        {...mergedPathProps}
      />
      <path
        d="M18.9999 6.1579H7.15784V21.1579H18.9999V6.1579ZM8.6381 7.6579H12.3388V13.6579L10.4884 12.5329L8.6381 13.6579V7.6579Z"
        {...mergedPathProps}
      />
    </svg>
  );
};

export default Program;
