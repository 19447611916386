import { algoliasearch, SearchClient } from "algoliasearch";
import { publicKey, appId } from "./constants";

let client: SearchClient;
export default function getPublicClient() {
  if (!publicKey) {
    throw new Error(
      "Missing environment variable: NEXT_PUBLIC_ALGOLIA_API_KEY",
    );
  }

  if (!appId) {
    throw new Error("Missing environment variable: NEXT_PUBLIC_APP_ID");
  }

  if (!client) {
    client = algoliasearch(appId, publicKey);
  }

  return client;
}
